<template>
  <div class="statistics">
    <div >
      <div class="statistics-tit">
        <span></span>
        <p>用户统计</p>
      </div>

      <div class="cont mt10">
        <div class="data-info">
          <!-- <div class="tit">院校统计</div> -->
          <div class="data-info-r-t">
            <div class="data-info-exam app-bj-color border-left-black">
              <div class="sub-tit">总览</div>
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.totalUserCount }}</p>
                  <p>用户总数</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.totalDataCount }}</p>
                  <p>总题数</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data" style="cursor: pointer;" @click="$router.push('system/user-stat')">
                  <p class="text-color">{{ info.onlineUserCount }}</p>
                  <p>实时在线人数</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data" style="cursor: pointer;" @click="$router.push('system/user-online-everyday')">
                  <p class="text-color">{{ info.allOnlineUserCount }}</p>
                  <p>今日累计在线人数</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.monthTrainUserCount }}</p>
                  <p>本月刷题用户</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.quarterTrainUserCount }}</p>
                  <p>本季度刷题用户</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.monthTrainDataCount }}</p>
                  <p>本月(道题)</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.quarterTrainDataCount }}</p>
                  <p>本季度(道题)</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.quarterTrainDataTotalCount }}</p>
                  <p>本季度(各月道数累计)</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="data-info mt20">
          <div class="tit">院校统计</div>
          <div class="data-info-r-t">
            <div class="data-info-exam app-bj-color border-left-red">
              <div class="sub-tit">学生统计</div>
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.schoolStudentCount }}</p>
                  <p>总学生数量</p>

                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.todaySchoolStudentCount }}</p>
                  <p>今日新增学生</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.onlineSchoolStudentCount }}</p>
                  <p>实时在线学生</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info['7daysActiveSchoolStudentCount'] }}</p>
                  <p>七日内活跃学生</p>
                </div>
              </div>
            </div>
            <div class="data-info-exam ml20 app-bj-color border-left-red">
              <div class="sub-tit">教师统计</div>
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.schoolTeacherCount }}</p>
                  <p>总教师数量</p>

                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.todaySchoolTeacherCount }}</p>
                  <p>今日新增教师</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.onlineSchoolTeacherCount }}</p>
                  <p>实时在线教师</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info['7daysActiveSchoolTeacherCount'] }}</p>
                  <p>七日内活跃教师</p>
                </div>
              </div>
            </div>
          </div>
          <div class="data-info-r-b app-bj-color">
            <!-- <div class="sub-tit">院校统计</div> -->
            <div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>总院校数量</p>
                <p>{{ info.schoolCount }}</p>
              </div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>有效院校</p>
                <p>{{ info.validSchoolCount }}</p>
              </div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>本周新增院校</p>
                <p>{{ info['7daysCreateUserSchoolCount'] }}</p>
              </div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>活跃院校</p>
                <p>{{ info['7daysActiveSchoolCount'] }}</p>
              </div>
            </div>

          </div>
        </div>
        <div class="data-info mt20">
          <div class="tit">C端统计</div>
          <div class="data-info-r-t">
            <div class="data-info-exam app-bj-color border-left-red">
              <div class="sub-tit">学生统计</div>
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.toCStudentCount }}</p>
                  <p>总学生数量</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.todayToCStudentCount }}</p>
                  <p>今日新增学生</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.onlineToCStudentCount }}</p>
                  <p>实时在线学生</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info['7daysActiveToCStudentCount'] }}</p>
                  <p>七日内活跃学生</p>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="data-info mt20">
          <div class="tit">企业统计</div>
          <div class="data-info-r-t">
            <div class="data-info-exam app-bj-color border-left-yellow">
              <div class="sub-tit">学员统计</div>
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.orgStudentCount }}</p>
                  <p>总学员数量</p>

                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.todayOrgStudentCount }}</p>
                  <p>今日新增学员</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.onlineOrgStudentCount }}</p>
                  <p>实时在线学员</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info['7daysActiveOrgStudentCount'] }}</p>
                  <p>七日内活跃学员</p>
                </div>
              </div>
            </div>
            <div class="data-info-exam ml20 app-bj-color border-left-yellow">
              <div class="sub-tit">激活码激活统计</div>
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.orgCdkTotalCount }}</p>
                  <p>分配总数</p>

                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.orgCdkUsedCount }}</p>
                  <p>已激活数量</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.orgCdkTodayActiveCount }}</p>
                  <p>今日激活</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.orgCdk7daysActiveCount }}</p>
                  <p>七日内激活</p>
                </div>
              </div>
            </div>
          </div>
          <div class="data-info-r-b app-bj-color">
            <!-- <div class="sub-tit">企业统计</div> -->
            <div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>总企业数量</p>
                <p>{{ info.orgCount }}</p>
              </div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>有效企业</p>
                <p>{{ info.validOrgCount }}</p>
              </div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>本周新增企业</p>
                <p>{{ info['7daysCreateUserOrgCount'] }}</p>
              </div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>活跃企业</p>
                <p>{{ info['7daysActiveOrgCount'] }}</p>
              </div>
            </div>

          </div>
        </div>
        <div class="data-info mt20">
          <div class="tit">经销商统计</div>
          <div class="data-info-r-t">
            <div class="data-info-exam app-bj-color border-left-green">
              <div class="sub-tit">学员统计</div>
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.brokerStudentCount }}</p>
                  <p>总学员数量</p>

                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.todayBrokerStudentCount }}</p>
                  <p>今日新增学员</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.onlineBrokerStudentCount }}</p>
                  <p>实时在线学员</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info['7daysActiveBrokerStudentCount'] }}</p>
                  <p>七日内活跃学员</p>
                </div>
              </div>
            </div>
            <div class="data-info-exam ml20 app-bj-color border-left-green">
              <div class="sub-tit">激活码激活统计</div>
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.brokerCdkTotalCount }}</p>
                  <p>分配总数</p>

                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.brokerCdkUsedCount }}</p>
                  <p>已激活数量</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.brokerCdkTodayActiveCount }}</p>
                  <p>今日激活</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.brokerCdk7daysActiveCount }}</p>
                  <p>七日内激活</p>
                </div>
              </div>
            </div>
          </div>
          <div class="data-info-r-b app-bj-color">
            <!-- <div class="sub-tit">经销商统计</div> -->
            <div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>总经销商数量</p>
                <p>{{ info.brokerCount }}</p>
              </div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>本周新增经销商</p>
                <p>{{ info['7daysCreateUserBrokerCount'] }}</p>
              </div>
              <div class="data-info-r-b-num">
                <p></p>
                <p>活跃经销商</p>
                <p>{{ info['7daysActiveBrokerCount'] }}</p>
              </div>
            </div>

          </div>
        </div>
<!--        <Row :gutter="10"  >-->
<!--          <Col :span="12">-->
<!--            <div class="cont-user">-->
<!--              <p class="tit">学生统计：</p>-->
<!--              <div class="cont-user-box">-->
<!--                <div class="cont-user-box-item" @click="toUserStudent()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.todayStudentUserCount}}</p>-->
<!--                  <p>今日新增</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="toUserStudent()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.studentUserCount }}</p>-->
<!--                  <p>总注册量</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="goUserStat" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.studentOnlineUserCount }}</p>-->
<!--                  <p>实时在线用户</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="goUserStatAll" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.studentUserCount7days }}</p>-->
<!--                  <p>7日内活跃用户</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Col>-->
<!--          <Col :span="6">-->
<!--            <div class="cont-user">-->
<!--              <p class="tit">教师统计：</p>-->
<!--              <div class="cont-user-box" >-->
<!--                <div class="cont-user-box-item" @click="toUserTeacher()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.todayTeacherUserCount }}</p>-->
<!--                  <p>今日新增</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="toUserTeacher()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.teacherUserCount }}</p>-->
<!--                  <p>总教师注册量</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="toUserTeacher()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.schoolTeacherUserCount }}</p>-->
<!--                  <p>院校教师</p>-->
<!--                </div>-->
<!--                &lt;!&ndash; <div class="cont-user-box-item" @click="goUserStat" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.teacherOnlineUserCount }}</p>-->
<!--                  <p>实时在线用户</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="goUserStatAll" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.teacherUserCount7days }}</p>-->
<!--                  <p>7日内活跃用户</p>-->
<!--                </div> &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </Col>-->
<!--          <Col :span="6">-->
<!--            <div class="cont-user">-->
<!--              <p class="tit">讲师统计：</p>-->
<!--              <div class="cont-user-box" >-->
<!--                <div class="cont-user-box-item" @click="toAuthTeacher()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.authTeacherUserTodayCount }}</p>-->
<!--                  <p>今日新增</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="toAuthTeacher()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.authTeacherUserCount }}</p>-->
<!--                  <p>总讲师注册量</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="toAuthTeacher()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.authTeacherUserPassCount }}</p>-->
<!--                  <p>审核通过</p>-->
<!--                </div>-->
<!--                &lt;!&ndash; <div class="cont-user-box-item" @click="goUserStat" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.teacherOnlineUserCount }}</p>-->
<!--                  <p>实时在线用户</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="goUserStatAll" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.teacherUserCount7days }}</p>-->
<!--                  <p>7日内活跃用户</p>-->
<!--                </div> &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </Col>-->
<!--        </Row>-->
<!--        <Row :gutter="10" class-name="mt10">-->
<!--          <Col :span="6">-->
<!--            <div class="cont-user">-->
<!--              <p class="tit">组织统计：</p>-->
<!--              <div class="cont-user-box">-->
<!--                <div class="cont-user-box-item" @click="toSite()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.todaySiteCount }}</p>-->
<!--                  <p>今日新增</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="toSite()" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.siteCount }}</p>-->
<!--                  <p>总组织数量</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item" @click="toSite" style="cursor: pointer">-->
<!--                  <p class="cont-user-box-item-num">{{ info.siteSchoolCount }}</p>-->
<!--                  <p>活跃组织</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Col>-->
<!--          <Col :span="6">-->
<!--            <div class="cont-user">-->
<!--              <p class="tit">邀请链接：</p>-->
<!--              <div class="cont-user-box">-->
<!--                <div class="cont-user-box-item">-->
<!--                  <p class="cont-user-box-item-num">{{ info.invitationSignupCount }}</p>-->
<!--                  <p>注册数</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item">-->
<!--                  <p class="cont-user-box-item-num">{{ info.invitationShowCount }}</p>-->
<!--                  <p>点击数</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Col>-->
<!--          <Col :span="6">-->
<!--            <div class="cont-user">-->
<!--              <p class="tit">刷题用户数：</p>-->
<!--              <div class="cont-user-box">-->
<!--                <div class="cont-user-box-item">-->
<!--                  <p class="cont-user-box-item-num">{{ info.monthTrainUserCount }}</p>-->
<!--                  <p>本月</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item">-->
<!--                  <p class="cont-user-box-item-num">{{ info.quarterTrainUserCount }}</p>-->
<!--                  <p>本季度</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Col>-->
<!--          <Col :span="6">-->
<!--            <div class="cont-user" >-->
<!--              <p class="tit">被刷题数：</p>-->
<!--              <div class="cont-user-box">-->
<!--                <div class="cont-user-box-item">-->
<!--                  <p class="cont-user-box-item-num">{{ info.monthTrainDataCount }}</p>-->
<!--                  <p>本月</p>-->
<!--                </div>-->
<!--                <div class="cont-user-box-item">-->
<!--                  <p class="cont-user-box-item-num">{{ info.quarterTrainDataCount }}</p>-->
<!--                  <p>本季度</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Col>-->
<!--        </Row>-->
      </div>
      <Row :gutter="10">
        <Col span="12">
          <div class="cont mt10">
            <div class="tit">邀请注册：</div>
            <Row :gutter="10"  >
              <Col :span="24">
                <div class="cont-user">
                  <!-- <p class="sub-tit">邀请注册统计：</p> -->
                  <div class="cont-user-box">
                    <div class="cont-user-box-item" style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.invitationShowCount}}</p>
                      <p>邀请注册点击量</p>
                    </div>
                    <div class="cont-user-box-item" style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.invitationSignupCount }}</p>
                      <p>邀请注册成功数</p>
                    </div>
                  </div>
                </div>
              </Col>

            </Row>

          </div>
        </Col>
        <Col span="12">
          <div class="cont mt10">
            <div class="tit">师资统计：</div>
            <Row :gutter="10"  >
              <Col :span="24">
                <div class="cont-user">
                  <!-- <p class="sub-tit">师资统计：</p> -->
                  <div class="cont-user-box">
                    <div class="cont-user-box-item" style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.authTeacherUserTodayCount}}</p>
                      <p>今日新增讲师</p>
                    </div>
                    <div class="cont-user-box-item" style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.authTeacherUserCount }}</p>
                      <p>总讲师注册量</p>
                    </div>
                    <div class="cont-user-box-item"  style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.authTeacherUserWaitCheckCount }}</p>
                      <p>待审核讲师</p>
                    </div>
                  </div>
                </div>
              </Col>

            </Row>
<!--            <div id="site-activity" class="num-chart"></div>-->
          </div>
        </Col>
      </Row>
      <Row :gutter="10">
        <Col span="24">
          <div class="cont mt10">
            <Row :gutter="10">
              <Col :span="24">
                <div class="cont-user">
                  <div class="cont-user-box">
                    <div class="cont-user-box-item"  style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.lastQuarterActiveAvgUserCount }}</p>
                      <p>上季度平均日活</p>
                    </div>
                    <div class="cont-user-box-item"  style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.lastQuarterTrainAvgUserDayCount }}</p>
                      <p>上季度平均日刷题人数</p>
                    </div>
                    <div class="cont-user-box-item"  style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.lastQuarterTrainUserCount }}</p>
                      <p>上季度累计刷题人数</p>
                    </div>
                    <div class="cont-user-box-item"  style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info.lastQuarterTrainUserDayCount }}</p>
                      <p>上季度累计刷题人天</p>
                    </div>
                    <div class="cont-user-box-item" style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info?.lastQuarterFirstMonthTrainDataCountMap?.count}}</p>
                      <p>{{ info?.lastQuarterFirstMonthTrainDataCountMap?.month?.slice(0,2) }}年{{ info?.lastQuarterFirstMonthTrainDataCountMap?.month?.slice(2) }}月练习数量</p>
                    </div>
                    <div class="cont-user-box-item" style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info?.lastQuarterSecondMonthTrainDataCountMap?.count }}</p>
                      <p>{{ info?.lastQuarterSecondMonthTrainDataCountMap?.month?.slice(0,2) }}年{{ info?.lastQuarterSecondMonthTrainDataCountMap?.month?.slice(2) }}月练习数量</p>
                    </div>
                    <div class="cont-user-box-item"  style="cursor: pointer">
                      <p class="cont-user-box-item-num">{{ info?.lastQuarterThirdMonthTrainDataCountMap?.count }}</p>
                      <p>{{ info?.lastQuarterThirdMonthTrainDataCountMap?.month?.slice(0,2) }}年{{ info?.lastQuarterThirdMonthTrainDataCountMap?.month?.slice(2) }}月练习数量</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row :gutter="10">
        <Col span="12">
          <div class="cont mt10">
            <div class="cont-tit">
              <div class="tit">组织在线用户统计Top10：</div>
              <Button type="primary" @click="checkOnlineUser">查看全部</Button>
            </div>
            <div id="site-online" class="num-chart"></div>
          </div>
        </Col>
        <Col span="12">
          <div class="cont mt10">
            <div class="cont-tit">
              <div class="tit">组织日活跃人数排行 Top10：</div>
              <Button type="primary" @click="checkActiveUser">查看全部</Button>
            </div>
            <div id="site-activity" class="num-chart"></div>
          </div>
        </Col>
      </Row>
      <div class="cont mt10">
        <p class="tit">组织区域分布图：</p>
        <div class="site-area">
          <Row>
            <Col :span="14">
              <div class="site-area-l">
                <p><span @click="showAllArea" style="cursor: pointer">全国</span> <span v-if="cityName">></span> {{ cityName }}</p>
                <div id="site-area" style="width: 100%;height: 500px"></div>
              </div>
            </Col>
            <Col :span="10">
              <div class="site-area-r" style="padding: 0">
                <p class="tit" style="padding: 20px">组织用户量排行：</p>
                <div class="absorb-r-cont" style="height: 500px" v-if="siteAreaDataList.length">
                  <div class="absorb-r-cont-item" v-for="item in siteAreaDataList" :key="item.id">
                    <div class="absorb-r-cont-item-top">
                      <div class="absorb-r-cont-item-name">{{ item.site.name }}</div>
                      <div class="absorb-r-cont-item-progress-nr">{{ item.count }}</div>
                    </div>
                    <div class="absorb-r-cont-item-progress">
                      <div class="progress-bj-area"><div class="progress-val" :style="{width: (siteAreaDataList[0].count != 0 ?  (item.count / siteAreaDataList[0].count) * 100 : 0) + '%'}"></div></div>
                    </div>
                  </div>
                </div>
                <div v-else style="height: 500px" class="no-data">
                  <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
                </div>
              </div>

            </Col>
          </Row>
<!--          <div class="site-area-l">-->
<!--            -->
<!--          </div>-->
<!--          <div class="site-area-r">-->
<!--            -->
<!--          </div>-->
        </div>
      </div>
      <div class="cont mt10">
        <div class="cont-tit">
          <p class="tit">刷题排行：</p>
          <div class="cont-tit-r">
            <div>
              <span>时间筛选：</span>
<!--              <DatePicker-->
<!--                  type="daterange"-->
<!--                  placeholder="请选择时间"-->
<!--                  format="yyyy/MM/dd"-->
<!--                  style="width: 200px"-->
<!--                  :value="rankDate"-->
<!--                  :split-panels="true"-->
<!--                  @on-change="changeRankDate"></DatePicker>-->
              <el-date-picker
                  v-model="rankDate"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  size="small"
                  @change="changeRankDate"
              >
              </el-date-picker>
            </div>
            <div>
              <span>组织筛选：</span>
              <Select v-model="rankSiteId"
                      ref="select"
                      remote
                      clearable
                      :remote-method="rankRemoteSite"
                      filterable  placeholder="请选择组织"
                      style="width: 200px;"
                      @on-change="changeRankSearch"
              >
                <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <Button type="primary" @click="exportRank">导出</Button>
          </div>
        </div>
        <div class="mt10">
          <Table :columns="rankColumns" :data="rankData" :loading="loading"></Table>
        </div>
        <div class="page mt10">
          <MyPage :total="rankTotal" :current="rankPage" @changePage="changeRankPage" @changePageSize="changeRankPageSize"></MyPage>
        </div>
      </div>

      <div class="cont mt10">
        <div class="cont-tit">
          <p class="tit">Chat GPT使用情况：</p>
          <div class="cont-tit-r">
            <div>
              <span>时间筛选：</span>
              <DatePicker
                type="daterange"
                placeholder="请选择时间"
                format="yyyy/MM/dd"
                style="width: 400px"
                v-model="gptForm.rankDate"
                @on-change="chatgptUseStat()"></DatePicker>
            </div>
          </div>
        </div>
        <div class="mt10">
          <Table :columns="gptColumn" :data="gptList"></Table>
        </div>
        <div class="page mt10">
          <MyPage :total="gptPagin.total" :current="gptPagin.page" @changePage="changeGptPage" @changePageSize="changeGptPageSize"></MyPage>
        </div>
      </div>

      <div class="cont mt10">
        <div class="cont-tit">
          <p class="tit">题库月度刷题情况：</p>
          <div class="cont-tit-r">
            <div>
              <span>月份筛选：</span>
              <DatePicker
                type="month"
                placeholder="请选择月份"
                format="yyyy/MM"
                style="width: 200px"
                v-model="trainForm.month"
                @on-change="monthTrainStat()"></DatePicker>
            </div>
          </div>
        </div>
        <div class="mt10">
          <Table :columns="trainColumn" :data="trainList"></Table>
        </div>
        <div class="page mt10">
          <MyPage :total="trainPagin.total" :current="trainPagin.page" @changePage="changeTrainPage" @changePageSize="changeTrainPageSize"></MyPage>
        </div>
      </div>

      <div class="statistics-tit mt20">
        <span></span>
        <p>场景统计</p>
      </div>
      <div class="cont mt10">

        <div class="cont-tit" style="border-bottom: 1px solid #EEEEEE;">
<!--          <p class="tit">刷题排行：</p>-->
          <div class="tab-list">
            <div  :class="curTabId == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item.id)">{{item.name}}</div>
          </div>
          <div class="cont-tit-r">
            <div>
              <span>时间筛选：</span>
              <DatePicker :value="scenceDate" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="请选择时间" style="width: 200px" @on-change="changeScenceDate"></DatePicker>
            </div>
            <div>
              <span>组织筛选：</span>
              <Select v-model="scenceSiteId"
                      ref="select"
                      remote
                      :remote-method="rankRemoteSite"
                      filterable  placeholder="请选择组织"
                      style="width: 200px"
                      clearable
                      @on-change="changeScenceSearch"
              >
                <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
        </div>
        <div class="attestation-cont">
          <div>
            <p class="tit">每日刷题人数</p>
            <div id="user-num" class="num-chart"></div>
          </div>
          <div>
            <p class="tit">每日刷题量</p>
            <div id="count-num" class="num-chart"></div>
          </div>
          <div style="padding: 0">
            <p class="tit" style="padding: 20px">各{{curTabId == 1 ? '认证' : '教材'}}刷题总量</p>
            <div class="absorb-r-cont" v-if="scenceTrainNum.length" >
              <div class="absorb-r-cont-item" v-for="item in scenceTrainNum" :key="item.id">
                <div class="absorb-r-cont-item-name">{{ item.category_name }}</div>
                <div class="absorb-r-cont-item-progress">
                  <div class="progress-bj"><div class="progress-val" :style="{width:  (scenceTrainNum[0].count!= 0 ? (item.count / scenceTrainNum[0].count)*100 : 0) + '%'}"></div></div>
                  <div class="absorb-r-cont-item-progress-nr">{{ item.count }}</div>
                </div>
              </div>
            </div>
            <div v-else class="no-data">
              <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
            </div>
          </div>
        </div>
        <Row :gutter="10" v-if="curTabId == 1">
          <Col>
            <div class="cont mt10">
              <div class="active-num-top">
                <div class="tit">分类每天刷题量</div>
              </div>
              <div class="search">
                <div class="search-item">
                  <div class="name">
                    一级分类:
                  </div>
                  <div class="search-cont">
                    <div class="values">
                      <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearchCategory(item,'curCategoryId')">{{item.name}}</div>
                    </div>
                  </div>
                </div>
                <div class="search-item" v-if="secondCategoryList.length">
                  <div class="name">
                    二级分类:
                  </div>
                  <div class="search-cont">
                    <div class="values" >
                      <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearchCategory(item,'cursecondCategoryId')">{{item.name}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="category-day-chart" class="num-chart" style="height: 400px"></div>
            </div>
          </Col>
        </Row>
      </div>
  <div class="cont mt10">
    <Row :gutter="10">
        <Col span="8">
          <div class="cont mt10">
            <div class="cont-tit">
              <div class="tit">组织星级评分：</div>
            </div>
            <div id="site-stat-by-star" class="num-chart"></div>
          </div>
        </Col>
        <Col span="8">
          <div class="cont mt10">
            <div class="cont-tit">
              <div class="tit">用户星级评分：</div>
            </div>
            <div id="user-stat-by-star" class="num-chart"></div>
          </div>
        </Col>
        <Col span="8">
          <div class="cont mt10">
            <div class="cont-tit">
              <div class="tit">试题星级评分：</div>
            </div>
            <div id="data-stat-by-star" class="num-chart"></div>
          </div>
        </Col>
      </Row>
  </div>
      <div class="statistics-tit mt20">
        <span></span>
        <p>各组织刷题排行</p>
      </div>
      <div class="cont mt10">
        <div class="cont-tit" style="border-bottom: 1px solid #EEEEEE;">
          <!--          <p class="tit">刷题排行：</p>-->
          <div class="tab-list">
            <div  :class="curSiteTabId == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeSiteTab(item.id)">{{item.name}}</div>
          </div>
          <div class="cont-tit-r">
            <div>
              <span>时间筛选：</span>
              <DatePicker :value="siteRankDate" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="请选择时间" style="width: 200px"
                @on-change="changeSiteRankDate"
              ></DatePicker>
            </div>
            <div>
              <span>组织筛选：</span>
              <Select v-model="siteRankSiteId"
                      ref="select"
                      remote
                      :remote-method="rankRemoteSite"
                      filterable  placeholder="请选择组织"
                      style="width: 200px"
                      clearable
                      @on-change="changeSiteRankSearch"
              >
                <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <Button type="primary"  @click="exportScence">导出</Button>
          </div>
        </div>
        <div class="mt10">
          <Table :columns="siteRankColumns" :data="siteRankData"></Table>
        </div>

        <div class="page mt10">
          <MyPage :total="siteRankTotal" :current="siteRankPage" @changePage="changeSiteRankPage" @changePageSize="changeSiteRankPageSize"></MyPage>
        </div>
      </div>
      <div class="statistics-tit mt20">
        <span></span>
        <p>功能统计</p>
      </div>
      <div class="cont mt10">
        <div class="cont-tit">
<!--          <p class="tit">各组织刷题排行：</p>-->
          <div class="cont-tit-r">
            <div>
              <span>时间筛选：</span>
              <DatePicker :value="funStatDate" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="请选择时间" style="width: 200px" @on-change="changeFunDate"></DatePicker>
            </div>
            <div>
              <span>组织筛选：</span>
              <Select v-model="funStatSiteId"
                      ref="select"
                      remote
                      :remote-method="rankRemoteSite"
                      filterable placeholder="请选择组织"
                      style="width: 200px"
                      clearable
                      @on-change="changeFunStatSearch"
              >
                <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
        </div>
        <Row :gutter="10">
          <Col :span="12">
            <p class="tit mt20">认证刷题功能统计</p>
            <div id="attestation-train-chart" class="num-chart"></div>
          </Col>
          <Col :span="12">
            <p class="tit mt20">认证专项刷题统计</p>
            <div id="attestation-category-chart" class="num-chart"></div>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col :span="12">
            <p class="tit mt20">教材练习功能刷题统计</p>
            <div id="material-train-chart" class="num-chart"></div>
          </Col>
          <Col :span="12">
            <p class="tit mt20">竞赛功能刷题统计</p>
            <div id="pk-train-chart" class="num-chart"></div>
          </Col>
        </Row>
      </div>
      <div class="statistics-tit mt20">
        <span></span>
        <p>师资统计</p>
      </div>
      <div class="cont mt10">
        <div class="tit">师资增量统计</div>
        <div class="search">
          <div class="search-item">
            <div class="name">
              时间:
            </div>
            <div class="search-cont">
              <div class="values">
                <div :class="dateType == item.id ? 'active' : ''" v-for="(item,index) in dateTypeList" :key="item.id" @click="changeTeacherSearch(item.id,'dateType')">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="num-chart" id="statistics-chart"></div>
        <div class="teacher-cont">
          <div class="cont mt10">
            <div class="tit" style="padding: 20px">技术方向</div>
            <div class="absorb-r-cont" style="height: 500px" v-if="info && info.userTeacherTypeTechDirrectionList.length">
              <div class="absorb-r-cont-item" v-for="item in info.userTeacherTypeTechDirrectionList" :key="item.id">
                <div class="absorb-r-cont-item-name">{{ item.value }}</div>
                <div class="absorb-r-cont-item-progress">
                  <div class="progress-bj-area"><div class="progress-val" :style="{width: (info.userTeacherTypeTechDirrectionList[0].count != 0 ?  (item.count / info.userTeacherTypeTechDirrectionList[0].count) * 100 : 0) + '%'}"></div></div>
                  <div class="absorb-r-cont-item-progress-nr">{{ item.count }}</div>
                </div>
              </div>
            </div>
            <div v-else style="height: 500px" class="no-data">
              <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
            </div>
          </div>
          <div class="cont mt10">
            <div class="tit" style="padding: 20px">技术体系</div>
            <div class="absorb-r-cont" style="height: 500px" v-if="info && info.userTeacherTypeTechSystemList.length">
              <div class="absorb-r-cont-item" v-for="item in info.userTeacherTypeTechSystemList" :key="item.id">
                <div class="absorb-r-cont-item-name">{{ item.value }}</div>
                <div class="absorb-r-cont-item-progress">
                  <div class="progress-bj-area"><div class="progress-val" :style="{width: (info.userTeacherTypeTechSystemList[0].count != 0 ?  (item.count / info.userTeacherTypeTechSystemList[0].count) * 100 : 0) + '%'}"></div></div>
                  <div class="absorb-r-cont-item-progress-nr">{{ item.count }}</div>
                </div>
              </div>
            </div>
            <div v-else style="height: 500px" class="no-data">
              <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
            </div>
          </div>
          <div class="cont mt10">
            <div class="tit" style="padding: 20px">含有经验</div>
            <div class="absorb-r-cont" style="height: 500px" v-if="info && info.userTeacherTypeWorkExpList.length">
              <div class="absorb-r-cont-item" v-for="item in info.userTeacherTypeWorkExpList" :key="item.id">
                <div class="absorb-r-cont-item-name">{{ item.value }}</div>
                <div class="absorb-r-cont-item-progress">
                  <div class="progress-bj-area"><div class="progress-val" :style="{width: (info.userTeacherTypeWorkExpList[0].count != 0 ?  (item.count / info.userTeacherTypeWorkExpList[0].count) * 100 : 0) + '%'}"></div></div>
                  <div class="absorb-r-cont-item-progress-nr">{{ item.count }}</div>
                </div>
              </div>
            </div>
            <div v-else style="height: 500px" class="no-data">
              <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- <Modal
        v-model="siteModal"
        :title="siteModalType == 1 ? '组织在线用户统计' :'组织日活跃人数排行 '"
        width="1000px"
    >
      <div>
        <span style="padding-right: 10px">组织搜索:</span>
        <Select v-model="siteModalId"
                ref="select"
                remote
                :remote-method="rankRemoteSite"
                filterable placeholder="请选择组织"
                style="width: 200px"
                clearable
                @on-change="changeSiteModalSearch"
        >
          <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <div class="mt10">
        <Table :columns="siteModalColumn" :data="siteModalData"></Table>
      </div>

      <div class="page mt10">
        <MyPage :total="siteModalTotal" :current="siteModalPage" @changePage="changeSiteModalPage" @changePageSize="changeSiteModalPageSize"></MyPage>
      </div>
    </Modal> -->

    <el-drawer :visible.sync="siteModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ siteModalType == 1 ? '组织在线用户统计' :'组织日活跃人数排行 ' }}</div>
      </div>
      <div style="padding:20px;">
        <div>
          <span style="padding-right: 10px">组织搜索:</span>
          <Select v-model="siteModalId"
                  ref="select"
                  remote
                  :remote-method="rankRemoteSite"
                  filterable placeholder="请选择组织"
                  style="width: 200px"
                  clearable
                  @on-change="changeSiteModalSearch"
          >
            <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </div>
          <div class="mt10">
          <Table :columns="siteModalColumn" :data="siteModalData"></Table>
        </div>

        <div class="page mt10">
          <MyPage :total="siteModalTotal" :current="siteModalPage" @changePage="changeSiteModalPage" @changePageSize="changeSiteModalPageSize"></MyPage>
        </div>
      </div>
    </el-drawer>


<!--    <Modal-->
<!--        v-model="userModal"-->
<!--        :title="userModalTit[userRole]"-->
<!--        width="1000px"-->
<!--    >-->

<!--      <div class="mt10">-->
<!--        <Table :columns="userModalColumn" :data="userModalData"></Table>-->
<!--      </div>-->

<!--      <div class="page mt10">-->
<!--        <MyPage :total="userModalTotal" :current="userModalPage" @changePage="changeUserModalPage" @changePageSize="changeUserModalPageSize"></MyPage>-->
<!--      </div>-->
<!--    </Modal>-->
<!--    <div class="no-data" >-->
<!--      <Spin fix></Spin>-->
<!--    </div>-->
  </div>
</template>

<script>
import StuTrainStat from '@/components/stuTrainStat'
import util from '@/utils/tools.js';

// import provinces from '@/assets/data/area.json';

export default {
  name: "statistics",
  data(){
    return {
      pageStatus:true,
      info:'',
      rankDate:[],
      rankSiteId:'',
      rankData:[],
      rankColumns:[
        {
          title: 'ID',
          key: 'user_id'
        },
        {
          title: '姓名',
          key: 'ID',
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.nickname);
          }
        },
        {
          title: '分类题库',
          key: 'category_name'
        },
        {
          title: '刷题量',
          key: 'count',
          width:80
        },
        {
          title:"浏览量",
          key:"view_count",
          width:80
        },
        {
          title: '正确率',
          key: 'right_rate'
        },
        {
          title: '所属组织',
          key: 'site_name'
        },
      ],
      rankTotal:0,
      rankPage:1,
      rankPageSize:10,
      siteList:[],
      curCategoryId:'',
      cursecondCategoryId:'',
      categoryList:[],
      secondCategoryList:[],
      siteRankDate:[],
      siteRankSiteId:'',
      siteRankData:[],
      siteRankColumns:[
        {
          title: 'ID',
          key: 'site_id'
        },
        {
          title: '组织名称',
          key: 'site'
        },
        {
          title: '刷题量',
          key: 'count'
        },
        {
          title: '活跃人数',
          key: 'user_count'
        }
      ],
      siteRankTotal:0,
      siteRankPage:1,
      siteRankPageSize:10,
      funStatDate:[],
      funStatSiteId:'',
      allAreaData:{},
      provinceData:{},
      provinceId:'',
      cityId:'',
      myChart:null,
      siteAreaDataList:[],
      siteAreaAllDataList:[],
      timer:null,
      loading:false,
      tabList:[
        {
          name:'认证',
          id:'1'
        },
        {
          name:'教材',
          id:'2'
        },
      ],
      curTabId:1,
      scenceDate:[],
      scenceSiteId:'',
      scenceTrainNum:[],
      curSiteTabId:1,
      dateTypeList:[],
      dateType:'',
      siteModal:false,
      siteModalId:'',
      siteModalType:1,
      siteModalPage:1,
      siteModalPageSize:10,
      siteModalTotal:0,
      siteModalData:[],
      siteModalColumn:[
        {
          title: '排行',
          key: 'rank'
        },
        {
          title: '组织ID',
          key: 'site_id'
        },
        {
          title: '组织名称',
          key: 'site'
        },
        {
          title: '活跃人数',
          key: 'count'
        },
        {
          title:"操作",
          render:(h,params)=>{
            return h("el-button",{
              props:{
                type:"primary",
                size:"small"
              },
              on:{
                click:()=>{
                  this.previewAll(params.row);
                }
              }
            },"查看");
          }
        }
      ],
      userModal:false,
      userModalTit:{
        "role_manager": "今日新增组织",
        "role_student": "今日新增学生",
        "role_teacher": "今日新增老师"
      },
      userRole:'role_student',
      userModalColumn:[
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: '组织名称',
          render:(h,params)=>{
            return h('span', params.row.site && params.row.site.name);
          }
        },
        {
          title: '昵称/姓名',
          key: 'nickname'
        },
        {
          title: '联系电话',
          key: 'mobile'
        },
        {
          title: '注册时间',
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
      ],
      userModalData:[],
      userModalTotal:0,
      userModalPage:1,
      userModalPageSize:10,
      option: {
        title: {
          text: "暂无数据",
          x: "center",
          y: "center",
          textStyle: {
            color: "#333333",
            fontWeight: "normal",
            fontSize: 16,
          },
        },
      },
      cityName:'',

      gptForm:{
        date_start:"",
        date_end:"",
        rankDate:[]
      },
      gptPagin:{
        page:1,
        page_size:10,
        total:0
      },
      gptList:[],
      gptColumn:[
        {
          title:"用户ID",
          key:"user_id",
        },
        {
          title:"调用次数",
          key:"cnt"
        },
        {
          title:"用户昵称",
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.nickname);
          }
        },
        {
          title:"手机号",
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.mobile);
          }
        },
        {
          title:"用户名称",
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.realname);
          }
        },
        {
          title:"组织",
          render:(h,params)=>{
            return h('span', params.row.site && params.row.site.name);
          }
        },
      ],

      trainForm:{
        month:"",
      },
      trainPagin:{
        page:1,
        page_size:10,
        total:0
      },
      trainList:[],
      trainColumn:[
        {
          title:"题库ID",
          key:"category_id"
        },
        {
          title:"刷题次数",
          key:"cnt"
        },
        {
          title:"题库",
          key:"category_name"
        },
      ]
    }
  },
  // components:{
  //   StuTrainStat
  // },
  beforeDestroy(){
    this.pageStatus = false;
  },
  mounted(){
    //this.productId = this.$route.params.id;
    this.getInfo(); //详情
    this.getSiteArea();
    this.getTrainStatList();
    this.getScenceStatData(); //场景统计
    this.getSiteStatList(); //各组织刷题排行
    this.getTeacherData();  //师资增量统计

    this.getStatByAuthItem();
    this.getStatByAuthFunction();
    this.getStatByMap();
    this.getStatByTypeMatch();

    this.getCategoryList();

    this.getDataStarData();
    this.getSiteStarData();
    this.getUserStarData();

    this.chatgptUseStat();
    this.monthTrainStat();
  },
  methods:{
    chatgptUseStat(){
      const { gptPagin,gptForm }=this;
      this.api.home.chatgptUseStat({
        site_id:"-1",
        user_id:"-1",
        date_start:new Date(gptForm.rankDate[0]).getTime()/1000,
        date_end:new Date(gptForm.rankDate[1]).getTime()/1000,
        ...gptPagin
      }).then(res=>{
        gptPagin.total=Number(res.count);
        this.gptList=res.list||[];
      });
    },
    changeGptPage(page){
      const { gptPagin }=this;
      gptPagin.page=page;
      this.chatgptUseStat();
    },
    changeGptPageSize(size){
      const { gptPagin }=this;
      gptPagin.page_size=size;
      this.chatgptUseStat();
    },

    monthTrainStat(){
      const { trainPagin }=this;
      console.log(this.trainForm);
      this.api.home.monthTrainStat({
        site_id:"-1",
        user_id:"-1",
        month:new Date(this.trainForm.month).getTime()/1000,
        ...trainPagin
      }).then(res=>{
        trainPagin.total=Number(res.count);
        this.trainList=res.list || [];
      });
    },
    changeTrainPage(page){
      const { trainPagin }=this;
      trainPagin.page=page;
      this.monthTrainStat();
    },
    changeTrainPageSize(size){
      const { trainPagin }=this;
      trainPagin.page_size=size;
      this.monthTrainStat();
    },


    getInfo(){
      let params = {
        module:'platform'
      };
      this.api.home.indexPlatformV1(params).then((res)=>{
          this.info = res;

          this.setSiteOnlineOption();
          this.setSiteActiveOption();
      })
    },
    setSiteOnlineOption(){

      let chartDom = document.getElementById('site-online');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.info.onlineUserSiteListTopTen.forEach((res)=>{
        names.push(res.site.name);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '180px',
          top:'20px'
        },
        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: names,
          inverse: true,
        },
        series: [
          {
            name: '人数',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };
      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    setSiteActiveOption(){

      let chartDom = document.getElementById('site-activity');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.info.userActiveSiteListTopTen.forEach((res)=>{
        names.push(res.site.name);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '180px',
          top:'20px'
        },
        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: names,
          inverse: true,
        },
        series: [
          {
            name: '人数',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };


      if(names.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    getSiteArea(){
      let params = {
        province_id:this.provinceId
      }
      this.api.home.siteAreaDistribute(params).then((res)=>{
        // console.log(res,'dddd')
        if(this.provinceId){
          this.provinceData = res.areaSiteData;
        }else{
          this.allAreaData = res.areaSiteData;
        }
        this.siteAreaAllDataList = res.top10;
        this.siteAreaDataList = res.top10;

        this.setChinaMap(res.areaSiteData); //中国地图
      })
    },
    getSiteProvinceArea(fn){
      let params = {
        province_id:this.provinceId,
        city_id:this.cityId
      }
      this.api.home.siteAreaDistribute(params).then((res)=>{
        // console.log(res,'dddd')
        this.provinceData = res.areaSiteData;

        this.siteAreaDataList = res.top10;

        fn && fn();

      })
    },
    showAllArea(){
      this.provinceId = '';
      this.cityId = '';
      //返回全国地图
      this.loadMap(require('@/assets/data/china.json'), 'china',this.myChart,this.allAreaData);
      this.siteAreaDataList = this.siteAreaAllDataList;
      this.cityName = '';
    },
    setChinaMap(data){
      //各省份的地图json文件
      let provinces = {
        "北京市":require("@/assets/data/bj.json"),
        "黑龙江省": require("@/assets/data/hlj.json"),
        "吉林省": require("@/assets/data/jl.json"),
        "辽宁省": require("@/assets/data/ln.json"),
        "内蒙古自治区": require("@/assets/data/nmg.json"),
        "河北省": require("@/assets/data/hb.json"),
        "天津市": require("@/assets/data/tj.json"),
        "河南省": require("@/assets/data/hn.json"),
        "山西省": require("@/assets/data/sx.json"),
        "陕西省": require("@/assets/data/sxs.json"),
        "山东省": require("@/assets/data/sd.json"),
        "江苏省": require("@/assets/data/js.json"),
        "上海市": require("@/assets/data/sh.json"),
        "浙江省": require("@/assets/data/zj.json"),
        "安徽省": require("@/assets/data/ah.json"),
        "福建省": require("@/assets/data/fj.json"),
        "广东省": require("@/assets/data/gd.json"),
        "香港特别行政区": require("@/assets/data/xg.json"),
        "澳门特别行政区": require("@/assets/data/am.json"),
        "台湾省": require("@/assets/data/tw.json"),
        "海南省": require("@/assets/data/hns.json"),
        "广西壮族自治区": require("@/assets/data/gx.json"),
        "云南省": require("@/assets/data/yn.json"),
        "西藏自治区": require("@/assets/data/xz.json"),
        "新疆维吾尔自治区": require("@/assets/data/xj.json"),
        "甘肃省": require("@/assets/data/gs.json"),
        "青海省": require("@/assets/data/qh.json"),
        "四川省": require("@/assets/data/sc.json"),
        "贵州省": require("@/assets/data/gz.json"),
        "重庆市": require("@/assets/data/cq.json"),
        "湖北省": require("@/assets/data/hb.json"),
        "湖南省": require("@/assets/data/hun.json"),
        "江西省": require("@/assets/data/jx.json"),
        "宁夏回族自治区": require("@/assets/data/nx.json")
      };
      let chartDom = document.getElementById('site-area');
      this.myChart = this.$echarts.getInstanceByDom(chartDom)
      if (this.myChart == null) {
        this.myChart = this.$echarts.init(chartDom);
      }

      this.loadMap(require('@/assets/data/china.json'), 'china',this.myChart,this.allAreaData);//初始化全国地图

      //单击切换到省级地图，当mapCode有值,说明可以切换到下级地图
      this.myChart.on('click', (params)=> {
        let name = params.name; //地区name
        this.cityName = name;
        let mapCode = provinces[name]; //地区的json数据
        if (!mapCode) { //
          this.cityId = params.data.id;
          this.getSiteProvinceArea();
        }else{
          this.provinceId = params.data.id;
          this.getSiteProvinceArea(()=>{
            this.loadMap(mapCode, name,this.myChart,this.provinceData);
          });
        }
      });
    },
    loadMap(mapCode, name,myChart,allData) {
      /**
       获取对应的json地图数据，然后向echarts注册该区域的地图，最后加载地图信息
       @params {String} mapCode:json数据的地址
       @params {String} name: 地图名称
       */
      // console.log(allData,'allData===============')
      let maxArr = allData.map((item)=>{
        return item.value;
      })
      const max = Math.max.apply(null, maxArr);
      this.$echarts.registerMap(name, mapCode);
      let option = {
        tooltip: {
          show: true,
          formatter: function(params) {
            if (params.data) return '<p>' + params.name + '</p>' + '<p>组织数量：' + params.data['value'] + '</p>'
          },
        },
        visualMap: {
          type: 'continuous',
          text: ['', ''],
          showLabel: true,
          left: '50',
          min: 0,
          max: max,
          inRange: {
            color: ['#edf1ff','#e4ebff','#b7c9ff','#92afff','#6e94ff','#4a79ff']
          },
          splitNumber: 0
        },
        series: [{
          name: 'MAP',
          type: 'map',
          geoIndex:0,
          mapType: name,
          selectedMode: 'false',//是否允许选中多个区域
          label: {
            normal: {
              show: true
            },
            emphasis: {
              show: true
            }
          },
          data: allData
        }]
      };
      myChart.setOption(option);
      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },

    rankRemoteSite (query) {
      // console.log(query,'query')
      clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        this.getSiteList(query);
      },300)

    },
    getSiteList(data){
      let params = {
        pageSize:20,
        page:1,
        keyword:data,
      };
      this.api.site.siteList(params).then((res)=>{
        this.siteList = res.list;
      })
    },
    changeRankSearch(){ //请求刷题排行列表
      this.rankPage = 1;
      this.getTrainStatList();
    },
    changeRankDate(data){
      console.log(data,'+++++++')
      this.rankDate = data;
      // console.log(this.rankDate,'this.rankDate')
      this.rankPage = 1;
      this.getTrainStatList();
    },
    changeRankPage(page){
      this.rankPage = page;
      //请求刷题排行列表
      this.getTrainStatList();
    },
    getTrainStatList(){  //刷题排行
      // console.log(this.rankDate,'rankDaterankDaterankDate')
      let params = {
        date_start:new Date(this.rankDate[0]).getTime()/1000,
        date_end:new Date(this.rankDate[1]).getTime()/1000,
        page:this.rankPage,
        pageSize:this.rankPageSize,
        site_id:this.rankSiteId > 0 ? this.rankSiteId : -1,
      }
      this.loading = true;
      this.api.dataset.datasetTrainStatUsers(params).then((res)=>{
        this.loading = false;
        this.rankData = res.list;
        this.rankTotal = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    exportRank(){
      let params = {
        date_start:new Date(this.rankDate[0]).getTime()/1000,
        date_end:new Date(this.rankDate[1]).getTime()/1000,
        page:this.rankPage,
        pageSize:this.rankPageSize,
        site_id:this.rankSiteId,
      }

      this.api.dataset.datasetTrainStatUsersExport(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res)
        })
      })
    },
    exportScence(){
      let params = {
        date_start:new Date(this.siteRankDate[0]).getTime()/1000,
        date_end:new Date(this.siteRankDate[1]).getTime()/1000,
        page:this.siteRankPage,
        pageSize:this.siteRankPageSize,
        site_id:this.siteRankSiteId,
        type:Number(this.curSiteTabId) + 2
      }

      this.api.home.siteStatListExport(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res)
        })
      })
    },
    changeRankPageSize(pageSize){
      this.rankPage = 1;
      this.rankPageSize = pageSize;
      //请求刷题排行列表
      this.getTrainStatList();
    },
    changeTab(id){
      this.curTabId = id;
      this.getScenceStatData();
    },
    //场景下
    getScenceStatData(){
      let params = {
        type:this.curTabId,
        site_id:this.scenceSiteId || -1,
        start_time:new Date(this.scenceDate[0]).getTime()/1000,
        end_time:new Date(this.scenceDate[1]).getTime()/1000,
      }
      this.api.home.scenceStatData(params).then((res)=>{
        this.setUserNumOption(res.statUserCountDay);
        this.setCountNumOption(res.statUserDayList);
        this.scenceTrainNum = res.statCategoryCountDay;
      })
    },
    setUserNumOption(data){
      let chartDom = document.getElementById('user-num');
      let myChart = this.$echarts.getInstanceByDom(chartDom)
      if (myChart == null) {
        myChart = this.$echarts.init(chartDom);
      }
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.date);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          right:0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '刷题人数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };
      if(names.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }
      // option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    setCountNumOption(data){
      let chartDom = document.getElementById('count-num');
      let myChart = this.$echarts.getInstanceByDom(chartDom)
      if (myChart == null) {
        myChart = this.$echarts.init(chartDom);
      }
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.date);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          right:0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '刷题量',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };
      if(names.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }
      // option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    changeScenceDate(data){
      this.scenceDate = data;
      // console.log(this.scenceDate,'this.scenceDate')
      this.getScenceStatData();
    },
    changeScenceSearch(){
      this.getScenceStatData();
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;
        this.getSecondCategoryList();
      })
    },
    getSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //二级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];

        this.cursecondCategoryId = this.secondCategoryList[0].id;

        this.trainCountStatByCategory();

      })
    },
    trainCountStatByCategory(){
      let params = {
        site_id:-1,
        user_id:-1,
        category_id:this.cursecondCategoryId
      }
      this.api.index.trainCountStatByCategory(params).then((res)=>{
        this.categoryDayStatList = res.statUserCategoryDayStat;
        let seriesData = [];
        let legendData = [];
        // this.categoryStatList = res.categoryStatList;
        if(res.statUserCategoryDayStat.length){
          let date = res.statUserCategoryDayStat[0].map((item)=>{
            return item.date;
          });
          res.statUserCategoryDayStat.forEach((item)=>{
            let data = [];
            item.forEach((sItem)=>{
              data.push(sItem.count)
            })
            seriesData.push({
              name: item[0].name,
              type: 'line',
              // stack: 'Total',
              data:data,
            });
            legendData.push(item[0].name)
          })
          this.$nextTick(()=>{
            // console.log(date,seriesData,legendData,'legendData')
            if(this.pageStatus){
              this.setCategoryDayChart(date,seriesData,legendData);
            }

          })
        }else{
          this.$nextTick(()=>{
            if(this.pageStatus){
              this.setCategoryDayChart([],[],[]);
            }
          })
        }
      })
    },
    setCategoryDayChart(date,seriesData,legendData){
      var chartDom = document.getElementById('category-day-chart');
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        tooltip:{
          trigger: 'axis',
          show:true
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: date
        },
        yAxis: {
          type: 'value'
        },
        series: seriesData
      };

      if(date.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }
      // option && myChart.setOption(option,true);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })

    },
    changeSearchCategory(data,name){
      this[name] = data.id;
      if(name == 'curCategoryId'){
        this.getSecondCategoryList();
      }
      if(name == 'cursecondCategoryId'){
        this.trainCountStatByCategory();
      }
    },
    changeSiteTab(id){
      this.curSiteTabId = id;
      this.getSiteStatList();
    },
    getSiteStatList(){  //场景各组织刷题排行
      // console.log(this.siteRankDate,'===========')
      let params = {
        type:Number(this.curSiteTabId) + 2,
        site_id:this.siteRankSiteId || -1,
        page:this.siteRankPage,
        pageSize:this.siteRankPageSize,
        start_time:new Date(this.siteRankDate[0]).getTime()/1000,
        end_time:new Date(this.siteRankDate[1]).getTime()/1000,
      }
      this.api.home.siteStatList(params).then((res)=>{
        this.siteRankData = res.list;
        // console.log(this.siteRankData,'this.siteRankData')
        this.siteRankTotal = +res.count;
      })
    },
    changeSiteRankDate(data){
      this.siteRankDate = data;

      this.siteRankPage = 1;
      // console.log(this.siteRankDate,'this.siteRankDatethis.siteRankDate')
      this.getSiteStatList();
    },
    changeSiteRankSearch(){ //请求刷题排行列表
      this.siteRankPage = 1;
      this.getSiteStatList();
    },
    changeSiteRankPage(page){
      this.siteRankPage = page;
      //请求刷题排行列表
      this.getSiteStatList();
    },
    changeSiteRankPageSize(pageSize){
      this.siteRankPage = 1;
      this.siteRankPageSize = pageSize;
      //请求刷题排行列表
      this.getSiteStatList();
    },
    changeTeacherSearch(data,name){
      if(data == this[name]) return;
      this[name] = data;
      this.getTeacherData();
    },
    getTeacherData(){
      let params = {
        date_type:this.dateType
      }
      this.api.user.teacherStat(params).then((res)=>{
        this.dateType = res.dateType
        let xAxisData = res.list.map((item)=>{
          return item.date
        });
        let seriesData = res.list.map((item)=>{
          return item.count
        });
        this.$nextTick(()=>{
          this.setOption(xAxisData,seriesData);
        })

        this.dateTypeList = [];

        for(let name in res.dateTypes){
          this.dateTypeList.push({
            name:res.dateTypes[name],
            id:name
          })
        }
      })
    },
    setOption(xAxisData,seriesData){
      var chartDom = document.getElementById('statistics-chart');
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        // title: {
        //   text: '师资注册量统计'
        // },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data:xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line'
          }
        ]
      };

      if(xAxisData.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }
      // option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    changeSiteModalSearch(){  //组织弹窗里的搜索
      this.siteModalPage = 1;
      this.getSiteStatModalList();
    },
    getSiteStatModalList(){
      let params = {
        type:this.siteModalType,
        site_id:this.siteModalId,
        page:this.siteModalPage,
        pageSize:this.siteModalPageSize
      }
      this.api.home.siteStatList(params).then((res)=>{
        this.siteModalData = res.list;
        this.siteModalTotal = +res.count;
      })
    },
    changeSiteModalPage(page){
      this.siteModalPage = page;
      this.getSiteStatModalList();
    },
    changeSiteModalPageSize(pageSize){
      this.siteModalPage = 1;
      this.siteModalPageSize = pageSize;
      this.getSiteStatModalList();
    },
    checkOnlineUser(){
      this.siteModal = true;
      this.siteModalType = 1;
      this.siteModalPage = 1;
      this.siteModalPageSize = 10;
      this.siteModalId = '';
      this.getSiteStatModalList();
    },
    checkActiveUser(){
      this.siteModal = true;
      this.siteModalType = 2;
      this.siteModalPage = 1;
      this.siteModalPageSize = 10;
      this.siteModalId = '';
      this.getSiteStatModalList();
    },
    previewAll(row){
      // console.log(row);
      this.$router.push({
        path:`/site/detail/statistics/${row.site_id}`
      });
    },
    // changeUserModalPage(page){
    //   this.userModalPage = page;
    //   this.getUserStatList();
    // },
    // changeUserModalPageSize(pageSize){
    //   this.userModalPage = 1;
    //   this.userModalPageSize = pageSize;
    //   this.getUserStatList();
    // },
    // getUserStatList(){
    //   let params = {
    //     page:this.userModalPage,
    //     pageSize:this.userModalPageSize,
    //     add_time_type:1,
    //     role:this.userRole,
    //     site_id:-1
    //   }
    //   this.api.user.userList(params).then((res)=>{
    //     this.userModalData = res.list;
    //     this.userModalTotal = +res.count;
    //
    //   })
    // },
    checkUser(url){
      // this.userModal = true;
      // this.userRole = role;
      // this.userModalPage = 1;
      // this.userModalPageSize = 10;
      // this.getUserStatList();
      this.$router.push({
        path:url
      })

    },
    changeFunDate(data){
      this.funStatDate = data;
      this.getStatByAuthItem();
      this.getStatByAuthFunction();
      this.getStatByMap();
      this.getStatByTypeMatch();
    },
    changeFunStatSearch(){
      this.getStatByAuthItem();
      this.getStatByAuthFunction();
      this.getStatByMap();
      this.getStatByTypeMatch();
    },
    getStatByAuthItem(){
      let params = {
        site_id:this.funStatSiteId || -1,
        start_time:new Date(this.funStatDate[0]).getTime()/1000,
        end_time:new Date(this.funStatDate[1]).getTime()/1000,
      };
      this.api.home.statByAuthItem(params).then((res)=>{
        let list = res.list;

        let seriesData = [];
        let legendData = [];

        let dates =[];

        if(list.length){
          dates = list[0].map((item)=>{
            return item.date;
          });

          list.forEach((item)=>{
            let data = [];
            item.forEach((sItem)=>{
              data.push(sItem.count)
            })
            seriesData.push({
              name: item[0].name,
              type: 'line',
              // stack: 'Total',
              data:data,
            });
            legendData.push(item[0].name)
          })
        }
        if(this.pageStatus){
          this.setTrainTypeAndDateRuleChart(dates,seriesData,legendData,'attestation-category-chart');
        }
      })
    },
    getStatByAuthFunction(){
      let params = {
        site_id:this.funStatSiteId || -1,
        start_time:new Date(this.funStatDate[0]).getTime()/1000,
        end_time:new Date(this.funStatDate[1]).getTime()/1000,
      };
      this.api.home.statByAuthFunction(params).then((res)=>{
        let list = res.list;

        let seriesData = [];
        let legendData = [];
        let dates =[];

        if(list.length){
          dates = list[0].map((item)=>{
            return item.date;
          });

          list.forEach((item)=>{
            let data = [];
            item.forEach((sItem)=>{
              data.push(sItem.count)
            })
            seriesData.push({
              name: item[0].name,
              type: 'line',
              // stack: 'Total',
              data:data,
            });
            legendData.push(item[0].name)
          })
        }

        if(this.pageStatus){
          this.setTrainTypeAndDateRuleChart(dates,seriesData,legendData,'attestation-train-chart');
        }
      })
    },
    getStatByMap(){
      let params = {
        site_id:this.funStatSiteId || -1,
        start_time:new Date(this.funStatDate[0]).getTime()/1000,
        end_time:new Date(this.funStatDate[1]).getTime()/1000,
      };
      this.api.home.statByMap(params).then((res)=>{
        let list = res.list;

        let seriesData = [];
        let legendData = [];
        let dates =[];

        if(list.length){
          dates = list[0].map((item)=>{
            return item.date;
          });

          list.forEach((item)=>{
            let data = [];
            item.forEach((sItem)=>{
              data.push(sItem.count)
            })
            seriesData.push({
              name: item[0].name,
              type: 'line',
              // stack: 'Total',
              data:data,
            });
            legendData.push(item[0].name)
          })
        }
        if(this.pageStatus){
          this.setTrainTypeAndDateRuleChart(dates,seriesData,legendData,'material-train-chart');
        }
      })
    },
    getStatByTypeMatch(){
      let params = {
        site_id:this.funStatSiteId || -1,
        start_time:new Date(this.funStatDate[0]).getTime()/1000,
        end_time:new Date(this.funStatDate[1]).getTime()/1000,
      };
      this.api.home.statByTypeMatch(params).then((res)=>{
        let list = res.list;

        let seriesData = [];
        let legendData = [];
        let dates =[];

        if(list.length){
          dates = list[0].map((item)=>{
            return item.date;
          });

          list.forEach((item)=>{
            let data = [];
            item.forEach((sItem)=>{
              data.push(sItem.count)
            })
            seriesData.push({
              name: item[0].name,
              type: 'line',
              // stack: 'Total',
              data:data,
            });
            legendData.push(item[0].name)
          })
        }

        if(this.pageStatus){
          this.setTrainTypeAndDateRuleChart(dates,seriesData,legendData,'pk-train-chart');
        }
      })
    },
    setTrainTypeAndDateRuleChart(date,seriesData,legendData,name){
      var chartDom = document.getElementById(name);
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        tooltip:{
          trigger: 'axis',
          show:true
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: date
        },
        yAxis: {
          type: 'value'
        },
        series: seriesData
      };

      if(date.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }
      // option && myChart.setOption(option,true);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })

    },

    getDataStarData(){
      let params = {
        site_id:-1,
        user_id:-1
      }
      this.api.dataset.dataStatByStar(params).then((res)=>{
              this.setDataStatByStarChart(res.list);
      })
    },
    setDataStatByStarChart(data){
      // console.log('setDataStatByStarChart')

      let chartDom = document.getElementById('data-stat-by-star');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.level);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show:false,
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          //right:0
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '题数',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      if(data.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },

    getUserStarData(){
      let params = {
        site_id:-1,
        user_id:-1
      }
      this.api.user.userStatByStar(params).then((res)=>{
              this.setUserStatByStarChart(res.list);
      })
    },
    setUserStatByStarChart(data){
      // console.log('setUserStatByStarChart')

      let chartDom = document.getElementById('user-stat-by-star');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.level);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show:false,
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          //right:0
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '用户数',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      if(data.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },

    getSiteStarData(){
      let params = {
        site_id:-1,
        user_id:-1
      }
      this.api.site.siteStatByStar(params).then((res)=>{
              this.setSiteStatByStarChart(res.list);
      })
    },
    setSiteStatByStarChart(data){
      // console.log('setSiteStatByStarChart')

      let chartDom = document.getElementById('site-stat-by-star');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.level);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show:false,
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          //right:0
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '组织数',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      if(data.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },


    toSite(){
      this.$router.push({
        path:'/site?time=today'
      })
    },
    toUserStudent(){
      this.$router.push({
        path:'/user/list?role=student&time=today'
      })
    },
    toUserTeacher(){
      this.$router.push({
        path:'/user/list?role=teacher&time=today'
      })
    },
    toAuthTeacher(){
      this.$router.push({
        path:'/user/teacher-list'
      })
    },
    goUserStat(){
      this.$router.push({
        path:'/system/user-stat'
      })
    },
    goUserStatAll(){
      this.$router.push({
        path:'/system/user-stat-all'
      })
    },
  }
}
</script>

<style scoped lang="scss">
.statistics{
  margin: 20px;
  //padding: 20px;
  font-size: 14px;
  color: #333333;
  //background: #ffffff;
  .statistics-tit{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >span{
      margin-right: 8px;
      display: inline-block;
      width: 6px;
      height: 18px;
      background: linear-gradient(93deg, #4A79FF, #0042FF);
      border-radius: 4px;
    }
    >p{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .data-info{
    .data-info-r-t{
      display: flex;
      justify-content: flex-start;

      .data-info-exam{
        padding:15px 20px;
        flex: 1;
        border-radius: 6px;

        color: #666666;
        //height: 92px;
        //>div{
        //  display: flex;
        //  justify-content: flex-start;
        //  align-items: center;
        //}
        .data-info-exam-value{
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 50px;
          text-align: center;
        }

        .data-info-exam-data{
          >p:first-child{
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .data-info-r-b{
      margin-top: 14px;
      padding: 25px 20px;

      background: #ECF4FA;
      border-radius: 6px;
      color: #666666;
      >div{
        display: flex;
        justify-content: flex-start;
      }

      .data-info-r-b-num{
        flex: 1;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p:first-child{
          margin-right: 6px;
          width: 8px;
          height: 8px;
          background: #333333;
        }
        >p:last-child{
          margin-left: 7px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .cont{
    background: #FFFFFF;
    padding: 20px;
    .cont-tit{
      //font-weight: bold;
      //font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;


      .tab-list{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 54px;
        font-size: 16px;
        >div{
          padding: 0 5px;
          margin-right: 30px;
          height: 54px;
          display: flex;
          align-items: center;
          border-bottom: 4px solid transparent;
          cursor: pointer;
        }
        .active{
          font-weight: bold;
          color: #4A79FF;
          border-bottom: 4px solid #5782FF;
        }
      }
      .cont-tit-r{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right:20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
  .tit{
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: bold;
  }
  .sub-tit{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .cont-user{
    //margin-right: 20px;
    padding: 20px;
    background: #F6F7FA;
    border-radius: 6px;
    .cont-user-box{
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;

      .cont-user-box-item-num{
        color: #4A79FF;
        font-size: 20px;
        font-weight: bold;
      }
      .cont-user-box-item-top{
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        >p:first-child{
          margin-right: 10px;
        }
      }
    }
  }

  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .search-cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .num-chart{
    width: 100%;
    height: 400px;
  }
  .site-area{
    border: 1px solid #EEEEEE;
    //display: flex;
    //justify-content: flex-start;
    //position: relative;
    height: 586px;
    .site-area-l{
      padding: 20px;
      width: 100%;
    }
    .site-area-r{
      width: 100%;
      padding: 20px;
      border-left: 1px solid #EEEEEE;
    }
  }
  .page{
    text-align: right;
  }
  .attestation-cont{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >div{
      margin-right: 20px;
      flex: 1;
      padding: 20px;
      height: 500px;
      border: 1px solid #EEEEEE;
    }
    >div:last-child{
      margin-right: 0;
    }
  }
  .absorb-r-cont{
    padding: 0 20px 20px 20px;
    height: 410px;
    overflow-y: auto;
    overflow-y: auto;

    .absorb-r-cont-item{
      .absorb-r-cont-item-top {
        display: flex;
        justify-content: space-between;
      }
      margin-bottom: 16px;
      .absorb-r-cont-item-name{
        margin-bottom: 5px;
      }
      .absorb-r-cont-item-progress{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .absorb-r-cont-item-progress-nr{
          margin-left: 16px;
        }
      }
    }
  }
  .progress-bj{
    flex: 1;
    height: 10px;
    background: #EEEEEE;
    border-radius: 5px;
    position: relative;
    .progress-val{
      width: 70%;
      height: 10px;
      background: #4A79FF;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .progress-bj-area{
    flex: 1;
    height: 10px;

    border-radius: 5px;
    position: relative;
    .progress-val{
      width: 70%;
      height: 10px;
      background: #4A79FF;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .teacher-cont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >div{
      margin-right: 20px;
      padding: 0;
      flex: 1;
      border: 1px solid #EEEEEE;
    }
    >div:last-child{
      margin-right: 0;
    }
  }
}
.page{
  text-align: right;
}
</style>
